import React, { useState, useRef } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Accordion, Card, Container, Button, Col, Form, InputGroup } from 'react-bootstrap';
import CtaMeet from "../components/CtaMeet"
import { Plus, Dash } from 'react-bootstrap-icons';
import Quote from '../components/Quote'
import LeftImg from "../components/LeftImg";
import RightImg from "../components/RightImg";
import TwoImageBlocks from "../components/TwoImageBlocks";
import TwoTextBlocks from "../components/TwoTextBlocks";
import ShareButtons from "../components/sharebuttons";
import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import Tweet from "../components/Tweet";
import { Seo } from "../components/Seo";
import metaImageOg from '../assets/images/apply-og.jpg';
import { useLocation } from "@reach/router"
import ReCAPTCHA from 'react-google-recaptcha'

const schema = yup.object({

    firstname: yup.string().min(2, 'Name too short.').required('Please write down your name.'),
    lastname: yup.string().min(2, 'Surname too short.').required('Please write down your surname.'),
    email: yup.string().email('Invalid email.').required('Please write down your email address.'),
    phone: yup.string(),
    links: yup.string(),
    position: yup.string(),
});

const ComponentName = ({
    data: {
        career: {
            positionTitle,
            location,
            date,
            accordion1,
            accordion1Content: json1,
            accordion2,
            accordion2Content: json2,
            accordion3,
            accordion3Content: json3,
            introduction: json4,
            slug
        } }
}) => {

    const acc1 = json1;
    const acc2 = json2;
    const acc3 = json3;

    const slugComponentMap = {
        "contact-us": CtaMeet,
        "quote": Quote,
        "left-img": LeftImg,
        "right-img": RightImg,
        "two-image-blocks": TwoImageBlocks,
        "two-text-blocks": TwoTextBlocks,
        "tweet": Tweet,
    };

    // const url1 = window.location.href;
    // console.log("URL-ja : "+url1);
    // const url= typeof window !== "undefined" ? window.location.href : "";
    // const url= "https://dev-sogody.netlify.app";

    const defaultInline = (type, node) => <span>type: {type} id: {node.data.target.sys.id}</span>;

    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
                const Comp = slugComponentMap[node.data.target.fields.slug['en-US']];
                const fields = node.data.target.fields;

                const props = Object.keys(fields).map(f => ({ [f]: fields[f]['en-US'] })).reduce((curr, acc) => ({ ...curr, ...acc }), {});
                return <Comp {...props} />
            },

            [INLINES.ASSET_HYPERLINK]: node => defaultInline(INLINES.ASSET_HYPERLINK, node),
            [INLINES.ENTRY_HYPERLINK]: node => defaultInline(INLINES.ENTRY_HYPERLINK, node),
            [INLINES.HYPERLINK]: (node) => {
                if (node.data.uri.indexOf('youtube.com') !== -1) {
                    return (
                        <div >
                            <iframe
                                title="career iframe"
                                id="ytplayer"
                                src={node.data.uri}
                                type="text/html"
                                width="640"
                                height="360"
                                frameBorder="0"
                                target="_blank"
                                rel="noreferrer"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture ; fullscreen"
                            />
                        </div>
                    );
                } else
                    return (
                        <a
                            href={node.data.uri}
                            target="_blank"
                        >
                            {node.content[0].value}
                        </a>
                    );
            },
            [BLOCKS.HEADING_1]: (node, children) =>
                (<h1 className="heading1">{children}</h1>),

            [BLOCKS.HEADING_2]: (node, children) =>
                (<h2 className="heading2">{children}</h2>),

            [BLOCKS.HEADING_3]: (node, children) =>
                (<h3 className="heading3">{children}</h3>),

            [BLOCKS.HEADING_4]: (node, children) =>
                (<h4 className="heading4">{children}</h4>),

            [BLOCKS.HEADING_5]: (node, children) =>
                (<h5 className="heading5">{children}</h5>),

            [BLOCKS.HEADING_6]: (node, children) =>
                (<h6 className="heading6">{children}</h6>),

            [BLOCKS.UL_LIST]: (node, children) =>
                (<ul className="ul-list">{children}</ul>),

            [BLOCKS.OL_LIST]: (node, children) =>
                (<ol className="ol-list">{children}</ol>),

            [BLOCKS.EMBEDDED_ASSET]: (node, children) =>
                (<img className="career-image" alt="update img" src={`https:${node.data.target.fields.file["en-US"].url}`} />),
            [BLOCKS.PARAGRAPH]: (node, children) =>
                (<p className="cr-paragraph">{children}</p>),
        },

        [BLOCKS.QUOTE]: (node, children) => <blockquote>{children(node.content)}</blockquote>,
        [BLOCKS.HR]: () => <hr />,
        renderMark: {
            [MARKS.CODE]: code => <code>{code}</code>,
            [MARKS.BOLD]: text => <b>{`${text}`} </b>,
            [MARKS.UNDERLINE]: text => <u>{text}</u>,
            [MARKS.ITALIC]: text => <i>{text}</i>,
        },

    }

    const pathLocation = useLocation();
    const url = pathLocation.href;

    const [resp, setResp] = useState(false);
    const [qual, setQual] = useState(false);
    const [offer, setOffer] = useState(true);
    const [open, setOpen] = useState(false);

    const [status, setStatus] = useState("Apply");
    const [resultForm, setResultForm] = useState(null);
    const [formError, setFormError] = useState(null);
    const fileInput = useRef(null);
    const recaptchaRef = useRef(null);

    const onSubmit = async (values, { resetForm, setSubmitting }) => {

        setStatus("Applying...");
        setSubmitting(true);
        setFormError(null);
        setResultForm(null);

        const token = await recaptchaRef.current.executeAsync();

        const formData = new FormData();

        formData.append('firstname', values.firstname);
        formData.append('lastname', values.lastname);
        formData.append('email', values.email);
        formData.append('phone', values.phone);
        formData.append('links', values.links);
        formData.append('content', values.content);
        formData.append('about', values.about);
        formData.append('position', positionTitle);
        formData.append('recaptchaValue', token);
        formData.append('cv', fileInput.current.files[0]);

        fetch("https://apply.sogody.com/", {
            method: 'POST',
            body: formData,
        })
            .then((response) => {
                if (!response.ok) {
                    return response.text().then(text => { throw new Error(text) })
                }

                return response;
            })
            .then((response) => {
                resetForm({
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: '',
                    links: '',
                    position: '',
                    about: '',
                });
                fileInput.current.value = "";
                setResultForm("Your application has been succesfully submited. If selected for an interview, you should hear back from our team.");
                setStatus("Apply");
                setSubmitting(false);
            })
            .catch((err) => {
                setFormError(`An error occurred: ${err}`);
                setStatus("Apply Again!");
                setSubmitting(false);
            });
    };

    const renderForm = (hidden) => {
        const style = hidden ? { display: 'none' } : {};
        return (<Formik
            initialValues={{
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                links: '',
                apply: 'apply',
                about: '',
                position: positionTitle,
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
        >
            {({ isSubmitting, handleChange, handleSubmit, values, errors }) => ( //web-develop // marketing
                <FormikForm method="POST" name="apply" style={style} onSubmit={handleSubmit}>
                    <input type="hidden" name="form-name" value={values.apply} />
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="firstname">
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="First name"
                                    aria-describedby="inputGroupPrepend"
                                    name="firstname"
                                    value={values.firstname}
                                    onChange={handleChange}
                                    isInvalid={!!errors.firstname}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.firstname ? (<p> {errors.firstname} </p>) : null}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="lastname">
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Last name"
                                    aria-describedby="inputGroupPrepend"
                                    name="lastname"
                                    value={values.lastname}
                                    onChange={handleChange}
                                    isInvalid={!!errors.lastname}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.lastname ? (<p> {errors.lastname} </p>) : null}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row style={{ display: "none" }}>
                        <Form.Group as={Col} md="12" controlId="position">
                            <InputGroup type="hidden">
                                <Form.Control
                                    type="text"
                                    placeholder="Position"
                                    aria-describedby="inputGroupPrepend"
                                    name="position"
                                    value={positionTitle}
                                    onChange={handleChange}
                                    isInvalid={!!errors.positionTitle}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.positionTitle ? (<p> {errors.positionTitle} </p>) : null}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="email">
                            <InputGroup>
                                <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    aria-describedby="inputGroupPrepend"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email ? (<p> {errors.email} </p>) : null}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="phone">
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Phone number"
                                    aria-describedby="inputGroupPrepend"
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    isInvalid={!!errors.phone}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.phone ? (<p> {errors.phone} </p>) : null}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="links">
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Provide links of your work via Git, Blog, Medium, Portfolio, Dribble (Separate with ,)"
                                    aria-describedby="inputGroupPrepend"
                                    name="links"
                                    value={values.links}
                                    onChange={handleChange}
                                    isInvalid={!!errors.links}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.links ? (<p> {errors.links} </p>) : null}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="about">
                            <InputGroup >
                                <Form.Control as="textarea" rows={3} type="text" placeholder="Let us know more about yourself..." aria-describedby="inputGroupPrepend" name="about" value={values.about} onChange={handleChange} isInvalid={!!errors.about} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.about ? (<p> {errors.about} </p>) : null}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="file">
                            <div>
                                <Form.File
                                    className="position-relative file-btn"
                                    required
                                    type="file"
                                    name="file"
                                    ref={fileInput}
                                    // style={{display: 'none'}} 
                                    onChange={handleChange}
                                    isInvalid={!!errors.file}
                                    feedback={errors.file}
                                    id="file-btn"
                                    accept="application/pdf, application/msword, text/plain"
                                    feedbackTooltip
                                />
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Button type="submit" className="apply-btn primary-btn-s" disabled={isSubmitting}>{status}</Button>
                        </Form.Group>
                        {resultForm !== null &&
                            <Form.Group as={Col} md="12">
                                <p className="career-result">{resultForm}</p>
                            </Form.Group>}
                        {formError !== null && <Form.Group as={Col} md="12">
                            <p className="career-error">{formError}</p>
                        </Form.Group>}
                    </Form.Row>
                    <ReCAPTCHA id='recaptcha' className="g-recaptcha" sitekey="6LdPd_MfAAAAAAVbxNvhyZU1bnuf_GisE8AtxA83" size="invisible" ref={recaptchaRef} />
                </FormikForm>
            )}
        </Formik>
        )

    }

    return (
        <> <Layout>
            <Seo 
                title={positionTitle + " - Sogody"} 
                description="A spiritful and intellectual community that challenges even the brightest minds!" 
                image={'https://sogody.com' + metaImageOg} 
                url={url}    
            />
            <div className="single-career-banner">
                <Container>
                    <img src={'https://sogody.com' + metaImageOg} style={{ display: "none" }} />
                    <h1 className="position-title">{positionTitle}</h1>
                    <p className="position-location">{location} - dl: {date}</p>
                </Container>

            </div>
            <div className="single-career-content">
                <Container className="up-container">
                    <div className="single-career">
                        {json4 && <div className="career-introduction">{documentToReactComponents(json4.json, options)}</div>}
                        <Accordion defaultActiveKey="0">
                            <Card className="project-details">
                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setResp(!resp)}>
                                    {accordion1} <span>{resp === false ? <Dash /> : <Plus />} </span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body> {documentToReactComponents(acc1.json, options)
                                        && (
                                            <span>{documentToReactComponents(acc1.json, options)}</span>
                                        )
                                    }
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion defaultActiveKey="0">
                            <Card className="project-details">
                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setQual(!qual)} >
                                    {accordion2} <span>{qual === false ? <Dash /> : <Plus />} </span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body> {documentToReactComponents(acc2.json, options)
                                        && (
                                            <p>{documentToReactComponents(acc2.json, options)}</p>
                                        )
                                    }
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion defaultActiveKey="1">
                            <Card className="project-details">
                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setOffer(!offer)}>
                                    {accordion3} <span>{offer === false ? <Dash /> : <Plus />} </span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body> {documentToReactComponents(acc3.json, options)
                                        && (
                                            <p>{documentToReactComponents(acc3.json, options)}</p>
                                        )
                                    }
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion defaultActiveKey="0">
                            <Card className="project-details">
                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setOpen(!open)}>
                                    Apply now <span>{open === false ? <Dash /> : <Plus />} </span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        {positionTitle.toLowerCase().indexOf("internship") === -1 ? <>
                                            <p className="cr-paragraph">Want to join the like-minded people at Sogody? Use the fields below to let us know more about you and please attach a CV in English and a link to your portfolio by <b>{date}</b></p>
                                            {renderForm()}
                                        </> : <>
                                            <p className="cr-paragraph">Want to join the like-minded people at Sogody? Submit the application form below by <b>{date}</b>.<br /><br /></p>
                                            <a href="https://forms.gle/aEnnRPH3puHF8b26A" target="_blank" rel="noreferrer" style={{ textDecoration: "underline", fontWeight: "normal", fontFamily: "HelveticaNeueLT Pro 45 Lt", fontSize: "16px" }}>Application Form</a>
                                        </>}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                    <ShareButtons url={url ? url : " "} title={"Sogody - " + positionTitle + " - " + location} summary={positionTitle} body={"Sogody - " + positionTitle + " - " + location + "\n\n" + "Visit: " + url + "\n"} />
                </Container>
            </div>
        </Layout> </>
    )
}

export const query = graphql`
query  GetSingleCareer($slug:String){
        career: contentfulJobPositions(slug: {eq: $slug}) {
            accordion1
            accordion1Content {
            json
            }
            accordion2
            accordion2Content {
            json
            }
            accordion3
            accordion3Content {
            json
            }
            introduction {
                json
            }
            slug
            positionTitle
            location
            date(formatString: "D.MM.YYYY")
        }
    }
    `

export default ComponentName

